import { useState, useEffect, useRef } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";

import moment from "moment";
import { generateReferral, getTransaction } from "../../services/transaction";
import { Footer } from "../widgets/Footer";
import { Navbar } from "../widgets/Navbar";
import { useLocation } from "react-router-dom";
import copy from "copy-to-clipboard";
import {
  EthRpcUrl,
  binanceChainId,
  bscRpcUrl,
  ethChainId,
} from "../../constant/index";
import { copyUrl } from "../../constant/Baseurl";

export const TransactionDetails = () => {
  const projectId = "4160d935fddfb1cfcb89bb947994839b";
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [currentPage, setCurrentPage] = useState(1);
  const [record, setRecord] = useState([]);
  const [totalUsdt, setTotalUsdt] = useState();
  const [totalToken, setTotalToken] = useState();
  const [bonus, setBonus] = useState("");
  const [referralBonus, setReferralBonus] = useState();
  const [totalBnbAmt, setTotalBnbAmt] = useState();
  const [totalBnbUsdtAmt, settotalBnbUsdtAmt] = useState();
  const [totalEthAmt, settotalEthAmt] = useState();
  const [totalEthUsdtAmt, settotalEthUsdtAmt] = useState();
  const [referral_code, setReferral_code] = useState();

  const [copyText, setCopyText] = useState("Copy Link");

  const mainnet = [
    {
      // chainId: 97,
      // name: "Binance Smart Chain",
      // currency: "BNB",
      // explorerUrl: "https://bscscan.com",
      // rpcUrl: bscRpcUrl,

      chainId: binanceChainId,
      name: "Binance Smart Chain Testnet",
      currency: "tBNB",
      explorerUrl: "https://testnet.bscscan.com",
      rpcUrl: bscRpcUrl,
    },
    {
      chainId: ethChainId,
      name: "Ethereum Testnet",
      currency: "ETH",
      explorerUrl: "https://testnet.etherscan.io",
      rpcUrl: EthRpcUrl,
      // chainId: ethChainId,
      // name: "Ethereum Mainnet",
      // currency: "ETH",
      // explorerUrl: "https://etherscan.io/",
      // // rpcUrl: 'https://cloudflare-eth.com'
      // rpcUrl: EthRpcUrl,
    },
  ];

  const bscRpc = bscRpcUrl;
  const EthRpc = EthRpcUrl;

  const metadata = {
    name: "crudo",
    description: "crudo",
    url: "https://crudoprotocol.com/",
    icons: ["https://crudoprotocol.com/html/images/crudo_Logo.svg"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  useEffect(() => {
    trnsactionGet();
  }, [address]);

  const copyToClipboard = (address) => {
    if (address) {
      copy(copyUrl + referral_code);
      setCopyText("Copied");
    }
  };

  const trnsactionGet = async () => {
    console.log(address, "address");
    if (address) {
      const addres = "0x1Dd8F6252D56671315cf283f0336d1Cd714Be89E";
      const resp = await getTransaction({ address });

      if (resp.status) {
        setRecord(resp.data);
        const totalAmt = Number(resp?.amount).toFixed(2);
        setTotalUsdt(totalAmt);
      
        const referralAmt = Number(resp?.referralAmount).toFixed(2);

        const allToken =( Number(resp?.tokenAmt)+Number(resp?.bonus)+Number(resp?.referralAmount)).toFixed(2)
        setTotalToken(allToken);

        setReferralBonus(referralAmt);
        setTotalBnbAmt(Number(resp?.totalBnbAmt).toFixed(5));
        settotalBnbUsdtAmt(Number(resp?.totalBnbUsdtAmt).toFixed(5));
        settotalEthAmt(Number(resp?.totalEthAmt).toFixed(5));
        settotalEthUsdtAmt(Number(resp?.totalEthUsdtAmt).toFixed(5));
        setBonus(resp?.bonus);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (address) {
        try {
          const result = await generateReferral({ wallet_address: address });

          setReferral_code(result?.data);
        } catch (error) {
          console.error("Error fetching referral:", error);
        }
      }
    };
    fetchData();
    trnsactionGet();
  }, [address]);

  // ============== pagination===================///
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = record.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  console.log(bonus, "bonus===");
  // ===================pagination=================//
  return (
    <>
      <nav className="navbar navbar-expand-sm nav_fix">
        <div className="container">
          <a className="navbar-brand" href="/">
            <img src="/assets/img/logo.png" alt="Logo" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapsibleNavbar"
          >
            <span className="navbar-toggler-icon" />
          </button>
          <div className="collapse navbar-collapse " id="collapsibleNavbar">
            <ul className="navbar-nav ms-md-auto me-md-4">
              <li className="nav-item">
                <a
                  className="nav-link"
                  target="_blank"
                  href="https://healix-protocol.gitbook.io/healix-whitepaper"
                >
                  {" "}
                  Whitepaper
                </a>
              </li>
              {/* <li className="nav-item">
                <a className="nav-link" href="#">
                  Connect
                </a>
              </li> */}
            </ul>
          </div>
        </div>
      </nav>
      <section className="top_box">
        <div className="container mt-4">
          <div className="row align-items-center">
            <div className="  col-md-6 text-center mb-md-0 mb-4">
              <img
                src="/assets/img/logo-l.svg"
                className="img-fluid"
                alt="logo"
              />
              <div className="audit">
                <h4>Audited By</h4>
                <a
                  href="https://skynet.certik.com/projects/healix-protocol"
                  target="_blank"
                >
                  <img
                    src="/assets/img/audit.png"
                    class="img-fluid"
                    alt="main-logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-6  ps-md-5 text-md-end">
              <h2 className="mb-4">User Dashboard</h2>
            </div>
          </div>
        </div>
      </section>

      <section className="ex_box p80 pn_page_pt  position-relative re_ovh">
        <img
          src="/assets/img/user_d_bg.png"
          className="user_d_bg position-absolute"
          alt="logo"
        />
        <div className="container  position-relative">
          {/* <div className="row text-center">
              <div className="col-md-6 mt-3">
                <div className="box p-md-4">
                  <h5 className="mb-0">
                    Total Amount Purchased:{totalUsdt ? totalUsdt : 0} USD
                  </h5>
                </div>
              </div>
              <div className="col-md-6 mt-3">
                <div className="box p-md-4">
                  <h5 className="mb-0">
                    Total HLX Tokens: {totalToken ? totalToken : 0}{" "}
                  </h5>
                </div>
              </div>
            </div>
            <div className="row text-center">
              {bonus ? (
                <div className="col-md-6 mt-3">
                  <div className="box p-md-4">
                    <h5 className="mb-0">Bonus Token: {bonus ? bonus : 0} </h5>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="col-md-6 mt-3">
                <div className="box p-md-4">
                  <h5 className="mb-0">
                    Referral Bonus Token: {referralBonus ? referralBonus : "0"}
                  </h5>
                </div>
              </div>
            </div> */}
          {/* <div className="d-md-flex align-items-center ">
                <span className="ttu fw600">Bonus Token: </span>
                <span className="ml-auto h5">
                {bonus ? bonus : "0"}
                </span>
              </div> */}

          <div className="row ">
            <div className="col-md-6 mt-3">
              <div className="box">
                <h2> My Deposits </h2>
                <hr />
                <table className="table table-striped my_deposits_table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Token</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody id="table_body">
                    <tr>
                      <td>
                        {" "}
                        <img
                          src="/assets/img/bnb1.png"
                          className="cww"
                          alt="logo"
                        />{" "}
                      </td>
                      <td>BNB </td>
                      <td>{totalBnbAmt ? totalBnbAmt : "0"} </td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <img
                          src="/assets/img/eth1.png"
                          className="cww"
                          alt="logo"
                        />{" "}
                      </td>
                      <td>Ethereum </td>
                      <td>{totalEthAmt ? totalEthAmt : "0"}</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <img
                          src="/assets/img/usdt1.png"
                          className="cww"
                          alt="logo"
                        />{" "}
                      </td>
                      <td>USDT </td>
                      <td> {totalEthUsdtAmt ? totalEthUsdtAmt : "0"}</td>
                    </tr>
                    <tr>
                      <td>
                        {" "}
                        <img
                          src="/assets/img/busd1.png"
                          className="cww"
                          alt="logo"
                        />{" "}
                      </td>
                      <td>BUSD </td>
                      <td> {totalBnbUsdtAmt ? totalBnbUsdtAmt : "0"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-6 mt-3">
              <div className="earn_bg  position-relative">
                <img
                  src="/assets/img/earnbg.png"
                  className="earnbg_img"
                  alt="earnbg"
                />
                <h2 className="mb-4">
                  {" "}
                  <span>Earn a</span> 5%
                  <br />
                  bonus{" "}
                </h2>
                <h3 className="mb-4">from all referred investors!</h3>

                <div className="refral_input mb-4">
                  <input
                    className="input-field"
                    placeholder={
                      address
                        ? `${copyUrl}${referral_code}`
                        : " Connect Wallet for Referral Link"
                    }
                    value={
                      address
                        ? `${copyUrl}${referral_code}`
                        : " Connect Wallet for Referral Link"
                    }
                    readOnly
                  />
                  <button
                          className="btn"
                          onClick={() => {
                            copyToClipboard(address);
                          }}
                        >
                          {copyText}
                        </button>
                </div>
              </div>
            </div>
            <div className="col-md-12 mt-3 mt-md-4">
              <div className="total">
                <img
                  src="/assets/img/total.png"
                  className="totaal_imt"
                  alt="earnbg"
                />
                <div className="row position-relative">
                  <div className="col-md-6 ms-auto">
                    <h2 className="mb-4 mb-md-5">
                      Total HLX
                      <br />
                      <span>{totalToken?totalToken:"0"}</span>
                    </h2>

                    <div className="t_s_box d-flex mb-3">
                      <span>Bonus</span>
                      <span className="ms-auto">{bonus?bonus:"0"}</span>
                    </div>
                    <div className="t_s_box d-flex mb-3">
                      <span>Referral Bonus</span>
                      <span className="ms-auto">{referralBonus?referralBonus:"0"}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-md-4">
            <div className="col-md-12 mt-3">
              <div className="UKC-middle-box box_table" id="buy-div">
                <h2>Recent Transactions</h2>
                <hr />
                <div className="select-currency-wrapper">
                  <div
                    className="wallet-code-disconnects mb-1 text-right"
                    id="conn-status"
                  ></div>
                  <div className="currency-select-wrap mb-1 table_scrool">
                    <div className="table-responsive">
                      <table
                        className="table table-striped "
                        style={{ color: "#fff" }}
                      >
                        <thead>
                          <tr>
                            <th>Sr No.</th>
                            <th>Chain</th>
                            <th>Currency</th>
                            <th>Amount</th>
                            <th>Token Amount</th>
                            <th>Status</th>
                            <th>Date Time</th>
                          </tr>
                        </thead>
                        <tbody id="table_body">
                          {currentItems &&
                            currentItems.map((rowData, index) => {
                              const date = moment(rowData.created_at).format(
                                "DD/MM/YYYY hh:mm A"
                              );

                              return (
                                <>
                                  <tr>
                                    <td>{index + 1}</td>
                                    <td>{rowData.chain}</td>
                                    <td>{rowData.currency}</td>
                                    <td>{rowData.amount}</td>
                                    <td>{rowData.token_amount}</td>
                                    <td>{rowData.status}</td>
                                    <td>{date}</td>
                                  </tr>
                                </>
                              );
                            })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="text-right mt-4">
                  <ul className="pagination d-inline-flex">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(currentPage - 1)}
                      >
                        Previous
                      </button>
                    </li>
                    {Array.from({
                      length: Math.ceil(record.length / itemsPerPage),
                    }).map((_, index) => (
                      <li
                        key={index}
                        className={`page-item ${
                          currentPage === index + 1 ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(index + 1)}
                        >
                          {index + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === Math.ceil(record.length / itemsPerPage)
                          ? "disabled"
                          : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => paginate(currentPage + 1)}
                      >
                        Next
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};
