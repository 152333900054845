import React, { useRef, useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
export const Platform = () => {
  useEffect(() => {
    AOS.init({
      offset: 200,
      duration: 400,
      easing: "ease-in-sine",
      delay: 100,
    });
  });

  const imgCoinRef = useRef(null);
  const headingRef = useRef(null);
  const utilityRef = useRef(null);
  const [scrollPercentage, setScrollPercentage] = useState(0);
  const [scrollUtilities, setScrollUtilities] = useState(0);
  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position and window height

      const scrollTop = window.scrollY;
      console.log(scrollTop, "scrollTop");

      if (scrollTop >= 5500) {
        let newScrollPercentage = Math.min(
          100,
          Math.floor(((scrollTop - 5500) / 5500) * 40) * 10
        );
        setScrollPercentage(newScrollPercentage);
      } else if (scrollTop >= 7450) {
        const newScrollPercentages = Math.min(
          100,
          Math.floor(((scrollTop - 7450) / 7450) * 50) * 10
        );
        setScrollUtilities(newScrollPercentages);
      } else {
        setScrollPercentage(0);
        setScrollUtilities(0);
      }
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (headingRef.current) {
      headingRef.current.style.clipPath = `polygon(0px 0px, ${scrollPercentage}% 0px, ${scrollPercentage}% 100%, 0% 100%)`;
      headingRef.current.style.opacity = `${scrollPercentage / 10}`;
    }

    if (utilityRef.current) {
      utilityRef.current.style.clipPath = `polygon(0px 0px, ${scrollUtilities}% 0px, ${scrollUtilities}% 100%, 0% 100%)`;
      utilityRef.current.style.opacity = `${scrollUtilities / 10}`;
    }
  }, [scrollPercentage, scrollUtilities]);

  useEffect(() => {
    const handleScroll = () => {
      // Get the current scroll position
      const scrollTop = window.scrollY;
      let translateY;
      // Calculate the transform values based on the scroll position
      if (scrollTop > 2600 && scrollTop < 3500) {
        translateY = -scrollTop / 15;
      } else {
        translateY = 0;
      }

      const translateZ = 0; // Adjust the factor as needed

      // Apply the transform to the image
      if (imgCoinRef.current) {
        imgCoinRef.current.style.transform = `translateY(${translateY}px) translateZ(${translateZ}px)`;
      }

      // setScrollPosition(scrollTop);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <>
      <>
        <section className="section-token  position-relative" >
          <img
            src="/assets/img/how2.png"
            alt="reward"
            className="position-absolute how2"
          />
          <div className="container    text-center  position-relative">
            <h1 className=" mb-2 mb-md-5 op_08">
              {" "}
              <span className="d-inline-flex align-items-center ani">
                <img src="/assets/img/logo.png" alt="logo" className="" />
                <h2 className="mb-0">
                  <span className="position-relative d-inline-block">
                    The HLX Token
                    <div ref={headingRef} style={{ opacity: 0 }}>
                      The HLX Token
                    </div>
                  </span>
                </h2>
              </span>
            </h1>
            <img
              src="/assets/img/tokenomics.png"
              alt="logo"
              className="w100 tokenomics"
              data-aos="zoom-in"
            />
            <h1 className=" mb-2 mb-md-5 op_08 mt-5 ani">Utilities</h1>
            <div className="token-1 position-relative">
              <img src="/assets/img//token1.png" alt="token" className="w100" />
              <div
                className="token-1-body"
                data-aos="fade-right"
                data-aos-anchor-placement="top-center"
                data-aos-duration={1500}
              >
                <p>
                  The HLX token serves as a loyalty token within the ecosystem,
                  allowing users to access exclusive discounts, offers, and
                  rewards from partner institutions.
                </p>
                <span />
                <h2>
                  <div className="fill_text">Loyalty</div>
                </h2>
              </div>
            </div>
          </div>
          <section className=" position-relative ">
            <img
              src="/assets/img/c1.png"
              alt="reward"
              className="position-absolute c1"
            />
            <img
              src="/assets/img/c2.png"
              alt="reward"
              className="position-absolute c2"
            />
            <div className="container position-relative">
              <div className="reward ">
                <div className="reward-body pt-md-5 pt-0">
                  <h2> Rewards</h2>
                  <p>
                    HLX token rewards users for contributing to the network,
                    achieving health goals, and engaging with the platform's
                    services and programs. Users earn rewards in the form of
                    additional HLX tokens, incentivizing healthy behaviors and
                    participation.
                  </p>
                  <img
                    data-aos="zoom-out-down"
                    src="/assets/img/payment.png"
                    alt="reward"
                  />
                </div>
                <div className="reward-body-2">
                  <img
                    data-aos="zoom-out-down"
                    src="/assets/img/reward.png"
                    alt="payment"
                  />
                  <h2 className="mt-3"> Payment</h2>
                  <p>
                    HLX token facilitates seamless transactions within the
                    ecosystem, enabling users to make payments for healthcare
                    services and wellness products.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </section>
        <section className=" position-relative rbg">
          <div className="container position-relative">
            <h1 className=" mb-5 text-center hadding">
              {" "}
              <span className="d-inline-flex align-items-center ">
                <img src="/assets/img/l-white.png" alt="logo" className="" />{" "}
                RoadMap
              </span>
            </h1>
            <div className="r_box mb-4 mb-md-5">
              <div className="text-end">
                <h4 className="phase_text" data-aos="fade-up">
                  <span className="text-clip">Phase 1</span>
                </h4>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <img src="/assets/img/r1.png" className="w100 br40" />
                </div>
                <div className="col-md-8 ps-md-5">
                  <ul className="r_list">
                    <li>Website Launch </li>
                    <li> Whitepaper Released </li>
                    <li>Presale Commences</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="r_box mb-4 mb-md-5">
              <div className="text-end">
                <h4 className="phase_text" data-aos="fade-up">
                  <span className="text-clip">Phase 2</span>
                </h4>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <img src="/assets/img/r2.png" className="w100 br40" />
                </div>
                <div className="col-md-8 ps-md-5">
                  <ul className="r_list">
                    <li> Smart Contract Audit Completed </li>
                    <li>Smart Contact Deployed </li>
                    <li>HLX Ambassador Program </li>
                    <li>HLX Airdrop </li>
                    <li>HLX App Development</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="r_box mb-4 mb-md-5">
              <div className="text-end">
                <h4 className="phase_text" data-aos="fade-up">
                  <span className="text-clip">Phase 3</span>
                </h4>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <img src="/assets/img/r3.png" className="w100 br40" />
                </div>
                <div className="col-md-8 ps-md-5">
                  <ul className="r_list">
                    <li>Presale concludes</li>
                    <li>HLX Partnership Announcements</li>
                    <li>DEX Listing</li>
                    <li>Tier 2 CEX Listing</li>
                    <li>Official HLX App released</li>
                    <li>Official HLX Merchandise unveiled</li>
                    <li>HLX Rewards Program unveiled</li>
                    <li>HLX User subscriptions unveiled</li>
                    <li>HLX AI Health Trainer unveiled</li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="r_box mb-4 mb-md-5">
              <div className="text-end">
                <h4 className="phase_text" data-aos="fade-up">
                  <span className="text-clip">Phase 4</span>
                </h4>
              </div>
              <div className="row align-items-center">
                <div className="col-md-4">
                  <img src="/assets/img/r4.png" className="w100 br40" />
                </div>
                <div className="col-md-8 ps-md-5">
                  <ul className="r_list">
                    <li>Tier 1 CEX Listing</li>
                    <li>Launch of HLX Health Wearables</li>
                    <li>Launch of HLX Generative AI Model</li>
                    <li>
                      Partnerships with top hospitals and wellness institutions
                    </li>
                    <li>HLX Partner Program released</li>
                    <li>
                      Testing of Healix Genome Sequencing Program commences
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="r_box mb-4 mb-md-5">
              <div className="row align-items-center">
                <div className="col-md-12 ps-md-5">
                  <div className="text-end">
                    <h4 className="phase_text" data-aos="fade-up">
                      <span className="text-clip">Phase 5</span>
                    </h4>
                  </div>
                  <ul className="r_list">
                    <li className="mt-0">
                      Launch of HLX Physical Wellness Centre
                    </li>
                    <li>Partnering with global sporting events</li>
                    <li>Official launch of Healix Genome Sequencing Program</li>
                    <li>Hosting the first HLX Global Wellness Summit</li>
                    <li>Hitting a million subscribers on the HLX App</li>
                    <li>
                      Launch of Healix Vault: A secure storage and management
                      platform for users' health data using SBTs (Soul Bound
                      Tokens) and DID (Decentralized Identities)
                    </li>
                  </ul>
                </div>
                <div className="col-md-12">
                  <img src="/assets/img/r5.png" className="w100 br40" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="  text-center p80 position-relative">
          <img
            src="/assets/img/how1.png"
            alt="reward"
            className="position-absolute how1"
          />
          <img
            src="/assets/img/how2.png"
            alt="reward"
            className="position-absolute how2"
          />
          <div className="container position-relative">
            <h1 className=" mb-5 op_08 text-center hadding">
              {" "}
              <span className="d-inline-flex align-items-center ">
                <img src="/assets/img/logo.png" alt="logo" className="" />
                How to buy HLX{" "}
              </span>
            </h1>
            <div className="row">
              <div className="col-md-11 m-auto">
                <img
                  src="/assets/img/buyHlx.png"
                  alt="reward"
                  className="img-fluid"
                  data-aos="zoom-in"
                />
              </div>
            </div>
          </div>
        </section>
        <section className="  position-relative">
          <div className="container position-relative">
            <div className="row">
              <div className="col-md-11 m-auto">
                <h1 className=" mb-5 op_08 text-end hadding2">
                  {" "}
                  Buy with Crypto
                </h1>
                <div className="stap_box position-relative mb-5">
                  <span className="stap_name">Step 1</span>
                  <div className="stap_hadding d-md-flex">
                    <img
                      src="/assets/img/metamask.png"
                      alt="reward"
                      className=""
                    />
                    <img
                      src="/assets/img/trust.png"
                      alt="reward"
                      className=""
                    />
                    <h3 data-aos="fade-up">
                      Install Metamask/
                      <br />
                      Trust Wallet
                    </h3>
                  </div>
                  <p className="mt-4" data-aos="fade-up">
                    Firstly, make sure you have one of the supported wallets
                    ready. For desktop, we recommend using the Metamask browser
                    extension. For mobile, we recommend using TrustWallet and
                    its built in browser.
                  </p>
                </div>
                <div className="stap_box position-relative mb-5">
                  <span className="stap_name">Step 2</span>
                  <div className="stap_hadding d-md-flex">
                    <img src="/assets/img/hlx.png" alt="reward" className="" />
                    <h3 data-aos="fade-up">Click on the "Buy $HLX"</h3>
                  </div>
                  <p className="mt-4" data-aos="fade-up">
                    Click on the "Buy $HLX" button to enable Healix to connect
                    with your wallet. Once connected, you can buy $HLX tokens
                    using a selection of currencies from the BNB chain.
                  </p>
                </div>
                <div className="stap_box position-relative mb-5">
                  <span className="stap_name">Step 3</span>
                  <div className="stap_hadding d-md-flex">
                    <img src="/assets/img/hlx2.png" alt="reward" className="" />
                    <h3 data-aos="fade-up">Choose amount of $HLX</h3>
                  </div>
                  <p className="mt-4" data-aos="fade-up">
                    Choose the amount of $HLX tokens you wish to buy or the
                    amount of your assets you wish to spend and click "Buy now".
                    If you don't have enough BNB please transfer it from any
                    crypto exchange to your wallet address or buy more through
                    Metamask or TrustWallet marketplace.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    </>
  );
};
